export const PoolInfos = [
    {
        name: "Super 13",
        operator: "DBet",
        payout: "80%",
        win1: "60%",
        win2: "20%",
        win3: "20%",
        win4: "",
        win5: "",
        fund: "",
        outcomes: "1 594 323",
        maxStake: "",
        stakePerRow: "1 Kr",
        guarantee: "",
        terms: "",
        numberOfGames: 13,
        wins: [
            { x: '2023-01-28', y: 186756 },
            { x: '2023-02-04', y: 246732 },
            { x: '2023-02-11', y: 789800 },
            { x: '2023-02-18', y: 754981 },
            { x: '2023-04-08', y: 288046 },
            { x: '2023-04-15', y: 94052 },

        ],
        turnover: [
            { x: '2023-01-28', y: 22949273 },
            { x: '2023-02-04', y: 23728607 },
            { x: '2023-02-11', y: 24236039 },
            { x: '2023-02-18', y: 23703512 },
            { x: '2023-04-08', y: 23688400 },
            { x: '2023-04-15', y: 24308365 },
        ]
    },
    {
        name: "Super 12",
        operator: "DBet",
        payout: "80%",
        win1: "60%",
        win2: "20%",
        win3: "20%",
        win4: "",
        win5: "",
        fund: "",
        outcomes: "531 441",
        maxStake: "",
        stakePerRow: "1 Kr",
        guarantee: "",
        terms: "",
        numberOfGames: 12,
        wins: [
            { x: '2023-01-29', y: 696928 },
            { x: '2023-02-05', y: 1366791 },
            { x: '2023-02-08', y: 242368 },
            { x: '2023-02-12', y: 16183 },
            { x: '2023-02-16', y: 1371915 },
            { x: '2023-02-19', y: 14582 },
            { x: '2023-04-02', y: 42814 },
            { x: '2023-04-05', y: 54942 },
            { x: '2023-04-09', y: 327050 },
            { x: '2023-04-13', y: 92594 },
            { x: '2023-04-16', y: 0 },
        ],
        turnover: [
            { x: '2023-01-29', y: 6487900 },
            { x: '2023-02-05', y: 6455767 },
            { x: '2023-02-08', y: 6292230 },
            { x: '2023-02-12', y: 6542560 },
            { x: '2023-02-16', y: 6379229 },
            { x: '2023-02-19', y: 6257386 },
            { x: '2023-04-02', y: 6169830 },
            { x: '2023-04-05', y: 6452320 },
            { x: '2023-04-09', y: 6172050 },
            { x: '2023-04-13', y: 6570279 },
            { x: '2023-04-16', y: 6322272 },
        ]
    },
    {
        name: "Super 8",
        operator: "DBet",
        payout: "80%",
        win1: "100%",
        win2: "",
        win3: "",
        win4: "",
        win5: "",
        fund: "",
        outcomes: "6 561",
        maxStake: "",
        stakePerRow: "1 Kr",
        guarantee: "",
        terms: "",
        numberOfGames: 8,
        wins: [
            { x: '2023-01-24', y: 15198 },
            { x: '2023-01-25', y: 751 },
            { x: '2023-01-26', y: 12718 },
            { x: '2023-01-27', y: 635 },
            { x: '2023-01-28', y: 3204 },
            { x: '2023-01-29', y: 15389 },
            { x: '2023-01-30', y: 14216 },
            { x: '2023-01-31', y: 1450 },
            { x: '2023-02-01', y: 1648 },
            { x: '2023-02-03', y: 1650 },
            { x: '2023-02-04', y: 1354 },
            { x: '2023-02-05', y: 1537 },
            { x: '2023-03-30', y: 5797 },
            { x: '2023-03-31', y: 15611 },
            { x: '2023-04-01', y: 5624 },
            { x: '2023-04-02', y: 1245 },
            { x: '2023-04-03', y: 11043 },
            { x: '2023-04-04', y: 5330 },
            { x: '2023-04-05', y: 2053 },
            { x: '2023-04-06', y: 862 },
            { x: '2023-04-07', y: 20669 },
            { x: '2023-04-08', y: 1642 },
            { x: '2023-04-09', y: 3240 },
            { x: '2023-04-10', y: 8799 },
            { x: '2023-04-11', y: 1754 },
            { x: '2023-04-12', y: 4554 },
            { x: '2023-04-13', y: 5703 },
            { x: '2023-04-14', y: 505 },
            { x: '2023-04-15', y: 2829 },
            { x: '2023-04-16', y: 9054 },
            { x: '2023-04-17', y: 18674 },
        ],
        turnover: [
            { x: '2023-01-24', y: 1457510 },
            { x: '2023-01-25', y: 1521833 },
            { x: '2023-01-26', y: 1415945 },
            { x: '2023-01-27', y: 1529803 },
            { x: '2023-01-28', y: 1516045 },
            { x: '2023-01-29', y: 1397370 },
            { x: '2023-01-30', y: 1434457 },
            { x: '2023-01-31', y: 1450545 },
            { x: '2023-02-01', y: 1407468 },
            { x: '2023-02-03', y: 1591670 },
            { x: '2023-02-04', y: 1493026 },
            { x: '2023-02-05', y: 1509067 },
            { x: '2023-03-30', y: 1566543 },
            { x: '2023-03-31', y: 1507862 },
            { x: '2023-04-01', y: 1815868 },
            { x: '2023-04-02', y: 1816752 },
            { x: '2023-04-03', y: 1460930 },
            { x: '2023-04-04', y: 1455316 },
            { x: '2023-04-05', y: 1495644 },
            { x: '2023-04-06', y: 1423931 },
            { x: '2023-04-07', y: 1540847 },
            { x: '2023-04-08', y: 1727534 },
            { x: '2023-04-09', y: 1869444 },
            { x: '2023-04-10', y: 1490520 },
            { x: '2023-04-11', y: 1635758 },
            { x: '2023-04-12', y: 1627013 },
            { x: '2023-04-13', y: 1460273 },
            { x: '2023-04-14', y: 1645952 },
            { x: '2023-04-15', y: 1737567 },
            { x: '2023-04-16', y: 1688480 },
            { x: '2023-04-17', y: 1534701 },
        ],
    },
    {
        name: "Stryktipset",
        operator: "Svenska Spel",
        payout: "65%",
        win1: "40%",
        win2: "15%",
        win3: "12%",
        win4: "25%",
        win5: "",
        fund: "8%",
        outcomes: "1 594 323",
        maxStake: "60 000 Kr",
        stakePerRow: "1 Kr",
        guarantee: "10 000 000 Kr",
        terms: "En ensam vinnare vinner garantin",
        numberOfGames: 13,
        wins: [
            { x: '2022-01-01', y: 1777777 },
            { x: '2022-01-08', y: 0 },
            { x: '2022-01-15', y: 1104499 },
            { x: '2022-01-22', y: 59522 },
            { x: '2022-01-29', y: 556423 },
            { x: '2022-02-05', y: 116372 },
            { x: '2022-02-05', y: 116372 },
            { x: '2022-02-12', y: 17291 },
            { x: '2022-02-19', y: 6284544 },
            { x: '2022-02-26', y: 168539 },
            { x: '2022-03-05', y: 60188 },
            { x: '2022-03-05', y: 60188 },
            { x: '2022-03-12', y: 387908 },
            { x: '2022-03-19', y: 351590 },
            { x: '2022-03-26', y: 6280 },
            { x: '2022-04-02', y: 7000000 },
            { x: '2022-04-02', y: 7000000 },
            { x: '2022-04-09', y: 143380 },
            { x: '2022-04-16', y: 407407 },
            { x: '2022-04-23', y: 105447 },
            { x: '2022-04-30', y: 5949 },
            { x: '2022-04-30', y: 5949 },
            { x: '2022-05-07', y: 288308 },
            { x: '2022-05-14', y: 605453 },
            { x: '2022-05-22', y: 3419 },
            { x: '2022-05-28', y: 440000 },
            { x: '2022-06-04', y: 0 },
            { x: '2022-06-04', y: 0 },
            { x: '2022-06-11', y: 2645193 },
            { x: '2022-06-18', y: 216581 },
            { x: '2022-06-26', y: 309973 },
            { x: '2022-07-02', y: 0 },
            { x: '2022-07-02', y: 0 },
            { x: '2022-07-09', y: 599739 },
            { x: '2022-07-16', y: 3410 },
            { x: '2022-07-23', y: 32638 },
            { x: '2022-07-30', y: 0 },
            { x: '2022-08-06', y: 151162 },
            { x: '2022-08-13', y: 558246 },
            { x: '2022-08-20', y: 2067713 },
            { x: '2022-08-27', y: 142804 },
            { x: '2022-09-03', y: 1000000 },
            { x: '2022-09-03', y: 1000000 },
            { x: '2022-09-10', y: 1223 },
            { x: '2022-09-17', y: 1609451 },
            { x: '2022-09-24', y: 380217 },
            { x: '2022-10-01', y: 714285 },
            { x: '2022-10-01', y: 714285 },
            { x: '2022-10-08', y: 102822 },
            { x: '2022-10-15', y: 144139 },
            { x: '2022-10-22', y: 56771 },
            { x: '2022-10-29', y: 2247830 },
            { x: '2022-11-05', y: 358974 },
            { x: '2022-11-05', y: 358974 },
            { x: '2022-11-12', y: 37726 },
            { x: '2022-11-19', y: 97676 },
            { x: '2022-11-26', y: 138823 },
            { x: '2022-12-03', y: 201029 },
            { x: '2022-12-03', y: 201029 },
            { x: '2022-12-10', y: 0 },
            { x: '2022-12-17', y: 2250000 },
            { x: '2022-12-26', y: 155688 },
        ],
        turnover: [
            { x: '2022-01-01', y: 26521247 },
            { x: '2022-01-08', y: 21280437 },
            { x: '2022-01-15', y: 29696460 },
            { x: '2022-01-22', y: 26098362 },
            { x: '2022-01-29', y: 23540979 },
            { x: '2022-02-05', y: 23274431 },
            { x: '2022-02-05', y: 23274431 },
            { x: '2022-02-12', y: 30478188 },
            { x: '2022-02-19', y: 29293781 },
            { x: '2022-02-26', y: 32693613 },
            { x: '2022-03-05', y: 28473780 },
            { x: '2022-03-05', y: 28473780 },
            { x: '2022-03-12', y: 25363280 },
            { x: '2022-03-19', y: 22988610 },
            { x: '2022-03-26', y: 19130382 },
            { x: '2022-04-02', y: 31526799 },
            { x: '2022-04-02', y: 31526799 },
            { x: '2022-04-09', y: 25367308 },
            { x: '2022-04-16', y: 25999547 },
            { x: '2022-04-23', y: 22711860 },
            { x: '2022-04-30', y: 28336072 },
            { x: '2022-04-30', y: 28336072 },
            { x: '2022-05-07', y: 28124275 },
            { x: '2022-05-14', y: 18629328 },
            { x: '2022-05-22', y: 22527630 },
            { x: '2022-05-28', y: 20745545 },
            { x: '2022-06-04', y: 13583755 },
            { x: '2022-06-04', y: 13583755 },
            { x: '2022-06-11', y: 16937713 },
            { x: '2022-06-18', y: 13328069 },
            { x: '2022-06-26', y: 14306447 },
            { x: '2022-07-02', y: 13219761 },
            { x: '2022-07-02', y: 13219761 },
            { x: '2022-07-09', y: 14460512 },
            { x: '2022-07-16', y: 12632051 },
            { x: '2022-07-23', y: 14300995 },
            { x: '2022-07-30', y: 16550185 },
            { x: '2022-08-06', y: 28283403 },
            { x: '2022-08-13', y: 23618114 },
            { x: '2022-08-20', y: 23858234 },
            { x: '2022-08-27', y: 26913070 },
            { x: '2022-09-03', y: 32127354 },
            { x: '2022-09-03', y: 32127354 },
            { x: '2022-09-10', y: 13285521 },
            { x: '2022-09-17', y: 24852071 },
            { x: '2022-09-24', y: 17548520 },
            { x: '2022-10-01', y: 32080792 },
            { x: '2022-10-01', y: 32080792 },
            { x: '2022-10-08', y: 24914600 },
            { x: '2022-10-15', y: 23838476 },
            { x: '2022-10-22', y: 24237253 },
            { x: '2022-10-29', y: 25936503 },
            { x: '2022-11-05', y: 30838334 },
            { x: '2022-11-05', y: 30838334 },
            { x: '2022-11-12', y: 17121865 },
            { x: '2022-11-19', y: 17474855 },
            { x: '2022-11-26', y: 14416285 },
            { x: '2022-12-03', y: 13917419 },
            { x: '2022-12-03', y: 13917419 },
            { x: '2022-12-10', y: 15622648 },
            { x: '2022-12-17', y: 17777291 },
            { x: '2022-12-26', y: 43622477 },
        ]
    },
    {
        name: "Europatipset",
        operator: "Svenska Spel",
        payout: "65%",
        win1: "40%",
        win2: "23%",
        win3: "12%",
        win4: "25%",
        win5: "",
        fund: "",
        outcomes: "1 594 323",
        maxStake: "60 000 Kr",
        stakePerRow: "1 Kr",
        guarantee: "",
        terms: "",
        numberOfGames: 13,
        wins: [
            { x: '2021-12-29', y: 62500 },
            { x: '2022-01-02', y: 5000000 },
            { x: '2022-01-06', y: 634036 },
            { x: '2022-01-09', y: 65089 },
            { x: '2022-01-12', y: 328874 },
            { x: '2022-01-16', y: 148390 },
            { x: '2022-01-19', y: 107972 },
            { x: '2022-01-23', y: 0 },
            { x: '2022-01-25', y: 324830 },
            { x: '2022-01-30', y: 230092 },
            { x: '2022-02-01', y: 5906 },
            { x: '2022-02-01', y: 5906 },
            { x: '2022-02-06', y: 1141449 },
            { x: '2022-02-06', y: 1141449 },
            { x: '2022-02-09', y: 653062 },
            { x: '2022-02-13', y: 128666 },
            { x: '2022-02-17', y: 0 },
            { x: '2022-02-20', y: 453864 },
            { x: '2022-02-23', y: 111244 },
            { x: '2022-02-27', y: 17695 },
            { x: '2022-03-02', y: 30167 },
            { x: '2022-03-02', y: 30167 },
            { x: '2022-03-06', y: 17931 },
            { x: '2022-03-06', y: 17931 },
            { x: '2022-03-10', y: 11708 },
            { x: '2022-03-13', y: 118863 },
            { x: '2022-03-16', y: 51879 },
            { x: '2022-03-20', y: 34453 },
            { x: '2022-03-24', y: 3961990 },
            { x: '2022-03-27', y: 15809 },
            { x: '2022-03-29', y: 20620 },
            { x: '2022-03-29', y: 20620 },
            { x: '2022-04-03', y: 13668 },
            { x: '2022-04-03', y: 13668 },
            { x: '2022-04-05', y: 136540 },
            { x: '2022-04-10', y: 400000 },
            { x: '2022-04-14', y: 86921 },
            { x: '2022-04-17', y: 222222 },
            { x: '2022-04-20', y: 143295 },
            { x: '2022-04-24', y: 827290 },
            { x: '2022-04-26', y: 365771 },
            { x: '2022-04-26', y: 365771 },
            { x: '2022-05-01', y: 4131 },
            { x: '2022-05-01', y: 4131 },
            { x: '2022-05-05', y: 374796 },
            { x: '2022-05-08', y: 48780 },
            { x: '2022-05-11', y: 47538 },
            { x: '2022-05-15', y: 209095 },
            { x: '2022-05-18', y: 0 },
            { x: '2022-05-21', y: 0 },
            { x: '2022-05-25', y: 2000000 },
            { x: '2022-05-29', y: 260632 },
            { x: '2022-06-02', y: 0 },
            { x: '2022-06-02', y: 0 },
            { x: '2022-06-05', y: 83641 },
            { x: '2022-06-05', y: 83641 },
            { x: '2022-06-09', y: 218701 },
            { x: '2022-06-12', y: 472921 },
            { x: '2022-06-14', y: 1039221 },
            { x: '2022-06-19', y: 1366085 },
            { x: '2022-06-23', y: 25469 },
            { x: '2022-06-28', y: 233630 },
            { x: '2022-06-28', y: 233630 },
            { x: '2022-07-03', y: 535580 },
            { x: '2022-07-03', y: 535580 },
            { x: '2022-07-05', y: 4428 },
            { x: '2022-07-10', y: 74153 },
            { x: '2022-07-12', y: 179787 },
            { x: '2022-07-17', y: 1687 },
            { x: '2022-07-21', y: 318989 },
            { x: '2022-07-24', y: 12527 },
            { x: '2022-07-28', y: 429477 },
            { x: '2022-07-31', y: 0 },
            { x: '2022-08-04', y: 1648299 },
            { x: '2022-08-07', y: 238038 },
            { x: '2022-08-11', y: 25228 },
            { x: '2022-08-14', y: 30647 },
            { x: '2022-08-18', y: 107042 },
            { x: '2022-08-21', y: 0 },
            { x: '2022-08-25', y: 485032 },
            { x: '2022-08-28', y: 388151 },
            { x: '2022-08-31', y: 47101 },
            { x: '2022-08-31', y: 47101 },
            { x: '2022-09-04', y: 479792 },
            { x: '2022-09-04', y: 479792 },
            { x: '2022-09-08', y: 0 },
            { x: '2022-09-11', y: 1500000 },
            { x: '2022-09-15', y: 40730 },
            { x: '2022-09-18', y: 794355 },
            { x: '2022-09-22', y: 120536 },
            { x: '2022-09-25', y: 13777 },
            { x: '2022-09-27', y: 694796 },
            { x: '2022-09-27', y: 694796 },
            { x: '2022-10-02', y: 17174 },
            { x: '2022-10-02', y: 17174 },
            { x: '2022-10-06', y: 1209390 },
            { x: '2022-10-09', y: 99401 },
            { x: '2022-10-13', y: 44906 },
            { x: '2022-10-16', y: 40856 },
            { x: '2022-10-19', y: 0 },
            { x: '2022-10-23', y: 362018 },
            { x: '2022-10-27', y: 72413 },
            { x: '2022-10-30', y: 70929 },
            { x: '2022-11-02', y: 0 },
            { x: '2022-11-02', y: 6068 },
            { x: '2022-11-06', y: 459721 },
            { x: '2022-11-06', y: 459721 },
            { x: '2022-11-09', y: 88251 },
            { x: '2022-11-13', y: 201894 },
            { x: '2022-11-16', y: 117125 },
            { x: '2022-11-21', y: 1000000 },
            { x: '2022-11-25', y: 833333 },
            { x: '2022-11-29', y: 5000000 },
            { x: '2022-11-29', y: 5000000 },
            { x: '2022-12-04', y: 257315 },
            { x: '2022-12-04', y: 257315 },
            { x: '2022-12-07', y: 151274 },
            { x: '2022-12-11', y: 262376 },
            { x: '2022-12-16', y: 127223 },
            { x: '2022-12-18', y: 0 },
            { x: '2022-12-21', y: 594827 },
            { x: '2022-12-29', y: 15843 },
            { x: '2023-01-01', y: 0 }
        ],
        turnover: [
            { x: '2021-12-29', y: 10877632 },
            { x: '2022-01-02', y: 10130325 },
            { x: '2022-01-06', y: 7503393 },
            { x: '2022-01-09', y: 10270510 },
            { x: '2022-01-12', y: 6486670 },
            { x: '2022-01-16', y: 9951253 },
            { x: '2022-01-19', y: 6388933 },
            { x: '2022-01-23', y: 10252559 },
            { x: '2022-01-25', y: 7686777 },
            { x: '2022-01-30', y: 8168981 },
            { x: '2022-02-01', y: 5801186 },
            { x: '2022-02-01', y: 5801186 },
            { x: '2022-02-06', y: 12507345 },
            { x: '2022-02-06', y: 12507345 },
            { x: '2022-02-09', y: 7728548 },
            { x: '2022-02-13', y: 10658763 },
            { x: '2022-02-17', y: 6289065 },
            { x: '2022-02-20', y: 11614860 },
            { x: '2022-02-23', y: 7021339 },
            { x: '2022-02-27', y: 11308088 },
            { x: '2022-03-02', y: 8816706 },
            { x: '2022-03-02', y: 8816706 },
            { x: '2022-03-06', y: 13587976 },
            { x: '2022-03-06', y: 13587976 },
            { x: '2022-03-10', y: 8517740 },
            { x: '2022-03-13', y: 13019070 },
            { x: '2022-03-16', y: 6753486 },
            { x: '2022-03-20', y: 12523969 },
            { x: '2022-03-24', y: 7600968 },
            { x: '2022-03-27', y: 6236430 },
            { x: '2022-03-29', y: 6902349 },
            { x: '2022-03-29', y: 6902349 },
            { x: '2022-04-03', y: 12398048 },
            { x: '2022-04-03', y: 12398048 },
            { x: '2022-04-05', y: 8705410 },
            { x: '2022-04-10', y: 13902636 },
            { x: '2022-04-14', y: 6171911 },
            { x: '2022-04-17', y: 11156833 },
            { x: '2022-04-20', y: 6783224 },
            { x: '2022-04-24', y: 9790415 },
            { x: '2022-04-26', y: 5771548 },
            { x: '2022-04-26', y: 5771548 },
            { x: '2022-05-01', y: 11065610 },
            { x: '2022-05-01', y: 11065610 },
            { x: '2022-05-05', y: 7243716 },
            { x: '2022-05-08', y: 12210521 },
            { x: '2022-05-11', y: 7126102 },
            { x: '2022-05-15', y: 11928635 },
            { x: '2022-05-18', y: 4554163 },
            { x: '2022-05-21', y: 7954368 },
            { x: '2022-05-25', y: 7866082 },
            { x: '2022-05-29', y: 6168810 },
            { x: '2022-06-02', y: 6010684 },
            { x: '2022-06-02', y: 6010684 },
            { x: '2022-06-05', y: 9166906 },
            { x: '2022-06-05', y: 9166906 },
            { x: '2022-06-09', y: 6039083 },
            { x: '2022-06-12', y: 7462269 },
            { x: '2022-06-14', y: 4099492 },
            { x: '2022-06-19', y: 5388899 },
            { x: '2022-06-23', y: 4923086 },
            { x: '2022-06-28', y: 5138767 },
            { x: '2022-06-28', y: 5138767 },
            { x: '2022-07-03', y: 6338235 },
            { x: '2022-07-03', y: 6338235 },
            { x: '2022-07-05', y: 3651201 },
            { x: '2022-07-10', y: 6481715 },
            { x: '2022-07-12', y: 3546097 },
            { x: '2022-07-17', y: 5485753 },
            { x: '2022-07-21', y: 4862310 },
            { x: '2022-07-24', y: 4843155 },
            { x: '2022-07-28', y: 5366370 },
            { x: '2022-07-31', y: 6569465 },
            { x: '2022-08-04', y: 6434872 },
            { x: '2022-08-07', y: 9390081 },
            { x: '2022-08-11', y: 5174986 },
            { x: '2022-08-14', y: 8825487 },
            { x: '2022-08-18', y: 5743631 },
            { x: '2022-08-21', y: 9389776 },
            { x: '2022-08-25', y: 7830299 },
            { x: '2022-08-28', y: 10718198 },
            { x: '2022-08-31', y: 7246433 },
            { x: '2022-08-31', y: 7246433 },
            { x: '2022-09-04', y: 12388898 },
            { x: '2022-09-04', y: 12388898 },
            { x: '2022-09-08', y: 6612851 },
            { x: '2022-09-11', y: 9733609 },
            { x: '2022-09-15', y: 5784171 },
            { x: '2022-09-18', y: 9400654 },
            { x: '2022-09-22', y: 5230370 },
            { x: '2022-09-25', y: 7010869 },
            { x: '2022-09-27', y: 6469104 },
            { x: '2022-09-27', y: 6469104 },
            { x: '2022-10-02', y: 10907429 },
            { x: '2022-10-02', y: 10907429 },
            { x: '2022-10-06', y: 7320372 },
            { x: '2022-10-09', y: 10195048 },
            { x: '2022-10-13', y: 5668641 },
            { x: '2022-10-16', y: 12483271 },
            { x: '2022-10-19', y: 8173061 },
            { x: '2022-10-23', y: 11897820 },
            { x: '2022-10-27', y: 6284404 },
            { x: '2022-10-30', y: 10072865 },
            { x: '2022-11-02', y: 6917780 },
            { x: '2022-11-02', y: 6917780 },
            { x: '2022-11-06', y: 13385439 },
            { x: '2022-11-06', y: 13385439 },
            { x: '2022-11-09', y: 6614476 },
            { x: '2022-11-13', y: 11149977 },
            { x: '2022-11-16', y: 4158299 },
            { x: '2022-11-21', y: 11883096 },
            { x: '2022-11-25', y: 11503266 },
            { x: '2022-11-29', y: 11586197 },
            { x: '2022-11-29', y: 11586197 },
            { x: '2022-12-04', y: 6090303 },
            { x: '2022-12-04', y: 6090303 },
            { x: '2022-12-07', y: 3580472 },
            { x: '2022-12-11', y: 5175073 },
            { x: '2022-12-16', y: 2509336 },
            { x: '2022-12-18', y: 5202038 },
            { x: '2022-12-21', y: 4450574 },
            { x: '2022-12-29', y: 7374901 },
            { x: '2023-01-01', y: 7887390 },
        ]
    },
    {
        name: "Topptipset",
        operator: "Svenska Spel",
        payout: "70%",
        win1: "100%",
        win2: "",
        win3: "",
        win4: "",
        win5: "",
        fund: "",
        outcomes: "6 561",
        maxStake: "20 000 Kr",
        stakePerRow: "1 Kr",
        guarantee: "",
        terms: "",
        numberOfGames: 8,
        wins: [
            { x: '2021-12-27', y: 2986 },
            { x: '2021-12-28', y: 7532 },
            { x: '2021-12-30', y: 752 },
            { x: '2022-01-03', y: 2548 },
            { x: '2022-01-04', y: 2906 },
            { x: '2022-01-05', y: 8001 },
            { x: '2022-01-07', y: 7884 },
            { x: '2022-01-08', y: 15126 },
            { x: '2022-01-09', y: 646 },
            { x: '2022-01-10', y: 3893 },
            { x: '2022-01-11', y: 1638 },
            { x: '2022-01-13', y: 9557 },
            { x: '2022-01-14', y: 6228 },
            { x: '2022-01-15', y: 8849 },
            { x: '2022-01-16', y: 1773 },
            { x: '2022-01-17', y: 2675 },
            { x: '2022-01-18', y: 3586 },
            { x: '2022-01-20', y: 1719 },
            { x: '2022-01-21', y: 2023 },
            { x: '2022-01-22', y: 8791 },
            { x: '2022-01-23', y: 1932 },
            { x: '2022-01-24', y: 1925 },
            { x: '2022-01-26', y: 6780 },
            { x: '2022-01-27', y: 1954 },
            { x: '2022-01-28', y: 2411 },
            { x: '2022-01-29', y: 988 },
            { x: '2022-01-30', y: 3245 },
            { x: '2022-01-31', y: 16259 },
            { x: '2022-01-31', y: 16259 },
            { x: '2022-02-02', y: 1494 },
            { x: '2022-02-02', y: 1494 },
            { x: '2022-02-03', y: 3005 },
            { x: '2022-02-03', y: 3005 },
            { x: '2022-02-04', y: 492 },
            { x: '2022-02-04', y: 492 },
            { x: '2022-02-05', y: 5454 },
            { x: '2022-02-05', y: 5454 },
            { x: '2022-02-06', y: 10696 },
            { x: '2022-02-06', y: 10696 },
            { x: '2022-02-07', y: 316 },
            { x: '2022-02-08', y: 513 },
            { x: '2022-02-10', y: 1754 },
            { x: '2022-02-11', y: 301 },
            { x: '2022-02-12', y: 2010 },
            { x: '2022-02-13', y: 6316 },
            { x: '2022-02-14', y: 7547 },
            { x: '2022-02-15', y: 2680 },
            { x: '2022-02-16', y: 58741 },
            { x: '2022-02-18', y: 24326 },
            { x: '2022-02-19', y: 23915 },
            { x: '2022-02-20', y: 3912 },
            { x: '2022-02-21', y: 8045 },
            { x: '2022-02-22', y: 1542 },
            { x: '2022-02-24', y: 874 },
            { x: '2022-02-25', y: 456 },
            { x: '2022-02-26', y: 7764 },
            { x: '2022-02-27', y: 136 },
            { x: '2022-02-28', y: 1558 },
            { x: '2022-02-28', y: 1558 },
            { x: '2022-03-01', y: 1727 },
            { x: '2022-03-01', y: 1727 },
            { x: '2022-03-03', y: 724 },
            { x: '2022-03-03', y: 724 },
            { x: '2022-03-04', y: 1416 },
            { x: '2022-03-04', y: 1416 },
            { x: '2022-03-05', y: 928 },
            { x: '2022-03-05', y: 928 },
            { x: '2022-03-06', y: 448 },
            { x: '2022-03-06', y: 448 },
            { x: '2022-03-07', y: 19407 },
            { x: '2022-03-08', y: 8916 },
            { x: '2022-03-09', y: 11646 },
            { x: '2022-03-11', y: 958 },
            { x: '2022-03-12', y: 9574 },
            { x: '2022-03-13', y: 1495 },
            { x: '2022-03-14', y: 516 },
            { x: '2022-03-15', y: 0 },
            { x: '2022-03-17', y: 12132 },
            { x: '2022-03-18', y: 2865 },
            { x: '2022-03-19', y: 4835 },
            { x: '2022-03-20', y: 275 },
            { x: '2022-03-21', y: 1496 },
            { x: '2022-03-22', y: 434 },
            { x: '2022-03-23', y: 2382 },
            { x: '2022-03-25', y: 26396 },
            { x: '2022-03-26', y: 394 },
            { x: '2022-03-27', y: 2694 },
            { x: '2022-03-28', y: 1267 },
            { x: '2022-03-28', y: 1267 },
            { x: '2022-03-30', y: 902 },
            { x: '2022-03-30', y: 902 },
            { x: '2022-03-31', y: 199 },
            { x: '2022-03-31', y: 199 },
            { x: '2022-04-01', y: 1455 },
            { x: '2022-04-01', y: 1455 },
            { x: '2022-04-02', y: 36896 },
            { x: '2022-04-02', y: 36896 },
            { x: '2022-04-03', y: 118 },
            { x: '2022-04-03', y: 118 },
            { x: '2022-04-04', y: 4792 },
            { x: '2022-04-06', y: 2176 },
            { x: '2022-04-07', y: 14189 },
            { x: '2022-04-08', y: 1232 },
            { x: '2022-04-09', y: 8506 },
            { x: '2022-04-10', y: 10512 },
            { x: '2022-04-11', y: 18590 },
            { x: '2022-04-12', y: 0 },
            { x: '2022-04-13', y: 917 },
            { x: '2022-04-15', y: 1908 },
            { x: '2022-04-16', y: 3413 },
            { x: '2022-04-17', y: 2377 },
            { x: '2022-04-18', y: 0 },
            { x: '2022-04-19', y: 829 },
            { x: '2022-04-21', y: 4319 },
            { x: '2022-04-22', y: 3672 },
            { x: '2022-04-23', y: 3984 },
            { x: '2022-04-24', y: 638 },
            { x: '2022-04-25', y: 1062 },
            { x: '2022-04-25', y: 1062 },
            { x: '2022-04-27', y: 0 },
            { x: '2022-04-27', y: 5459 },
            { x: '2022-04-28', y: 2749 },
            { x: '2022-04-28', y: 2749 },
            { x: '2022-04-29', y: 5546 },
            { x: '2022-04-29', y: 5546 },
            { x: '2022-04-30', y: 255 },
            { x: '2022-04-30', y: 255 },
            { x: '2022-05-01', y: 203 },
            { x: '2022-05-01', y: 203 },
            { x: '2022-05-02', y: 3569 },
            { x: '2022-05-03', y: 1065 },
            { x: '2022-05-04', y: 3766 },
            { x: '2022-05-06', y: 4608 },
            { x: '2022-05-07', y: 8674 },
            { x: '2022-05-08', y: 853 },
            { x: '2022-05-09', y: 1461 },
            { x: '2022-05-10', y: 3163 },
            { x: '2022-05-12', y: 210 },
            { x: '2022-05-13', y: 1581 },
            { x: '2022-05-14', y: 12794 },
            { x: '2022-05-15', y: 6108 },
            { x: '2022-05-16', y: 0 },
            { x: '2022-05-17', y: 0 },
            { x: '2022-05-19', y: 2759 },
            { x: '2022-05-20', y: 2812 },
            { x: '2022-05-21', y: 59710 },
            { x: '2022-05-22', y: 99 },
            { x: '2022-05-23', y: 0 },
            { x: '2022-05-24', y: 7300 },
            { x: '2022-05-26', y: 3297 },
            { x: '2022-05-27', y: 0 },
            { x: '2022-05-28', y: 4310 },
            { x: '2022-05-29', y: 1042 },
            { x: '2022-05-30', y: 460 },
            { x: '2022-05-30', y: 460 },
            { x: '2022-05-31', y: 16872 },
            { x: '2022-05-31', y: 16872 },
            { x: '2022-06-01', y: 229 },
            { x: '2022-06-01', y: 229 },
            { x: '2022-06-03', y: 1678 },
            { x: '2022-06-03', y: 1678 },
            { x: '2022-06-04', y: 11671 },
            { x: '2022-06-04', y: 11671 },
            { x: '2022-06-05', y: 2591 },
            { x: '2022-06-05', y: 2591 },
            { x: '2022-06-06', y: 41423 },
            { x: '2022-06-07', y: 173 },
            { x: '2022-06-08', y: 1418 },
            { x: '2022-06-10', y: 8482 },
            { x: '2022-06-11', y: 17556 },
            { x: '2022-06-12', y: 897 },
            { x: '2022-06-13', y: 2398 },
            { x: '2022-06-15', y: 2297 },
            { x: '2022-06-16', y: 3077 },
            { x: '2022-06-17', y: 713 },
            { x: '2022-06-18', y: 1134 },
            { x: '2022-06-19', y: 21891 },
            { x: '2022-06-20', y: 1351 },
            { x: '2022-06-21', y: 7098 },
            { x: '2022-06-22', y: 678 },
            { x: '2022-06-24', y: 870 },
            { x: '2022-06-25', y: 1651 },
            { x: '2022-06-25', y: 1651 },
            { x: '2022-06-26', y: 3695 },
            { x: '2022-06-27', y: 0 },
            { x: '2022-06-27', y: 235 },
            { x: '2022-06-29', y: 166 },
            { x: '2022-06-29', y: 166 },
            { x: '2022-06-30', y: 8110 },
            { x: '2022-06-30', y: 8110 },
            { x: '2022-07-01', y: 1716 },
            { x: '2022-07-01', y: 1716 },
            { x: '2022-07-02', y: 32910 },
            { x: '2022-07-02', y: 32910 },
            { x: '2022-07-03', y: 4795 },
            { x: '2022-07-03', y: 4795 },
            { x: '2022-07-04', y: 11302 },
            { x: '2022-07-06', y: 174 },
            { x: '2022-07-06', y: 174 },
            { x: '2022-07-07', y: 441 },
            { x: '2022-07-08', y: 1660 },
            { x: '2022-07-09', y: 5091 },
            { x: '2022-07-10', y: 5087 },
            { x: '2022-07-11', y: 0 },
            { x: '2022-07-11', y: 65 },
            { x: '2022-07-13', y: 982 },
            { x: '2022-07-14', y: 6915 },
            { x: '2022-07-15', y: 0 },
            { x: '2022-07-15', y: 381 },
            { x: '2022-07-16', y: 0 },
            { x: '2022-07-17', y: 98 },
            { x: '2022-07-18', y: 4676 },
            { x: '2022-07-19', y: 28029 },
            { x: '2022-07-20', y: 16822 },
            { x: '2022-07-22', y: 1118 },
            { x: '2022-07-23', y: 0 },
            { x: '2022-07-24', y: 0 },
            { x: '2022-07-25', y: 1347 },
            { x: '2022-07-26', y: 1258 },
            { x: '2022-07-27', y: 12199 },
            { x: '2022-07-29', y: 0 },
            { x: '2022-07-30', y: 6074 },
            { x: '2022-07-31', y: 46315 },
            { x: '2022-08-01', y: 296 },
            { x: '2022-08-02', y: 1486 },
            { x: '2022-08-03', y: 279 },
            { x: '2022-08-05', y: 1790 },
            { x: '2022-08-06', y: 0 },
            { x: '2022-08-07', y: 10362 },
            { x: '2022-08-08', y: 2791 },
            { x: '2022-08-09', y: 6583 },
            { x: '2022-08-10', y: 1430 },
            { x: '2022-08-12', y: 11483 },
            { x: '2022-08-13', y: 0 },
            { x: '2022-08-14', y: 1721 },
            { x: '2022-08-15', y: 684 },
            { x: '2022-08-16', y: 12562 },
            { x: '2022-08-17', y: 1461 },
            { x: '2022-08-19', y: 2592 },
            { x: '2022-08-20', y: 9988 },
            { x: '2022-08-21', y: 31314 },
            { x: '2022-08-22', y: 2202 },
            { x: '2022-08-23', y: 814 },
            { x: '2022-08-24', y: 460 },
            { x: '2022-08-26', y: 1499 },
            { x: '2022-08-27', y: 472 },
            { x: '2022-08-28', y: 2776 },
            { x: '2022-08-29', y: 363 },
            { x: '2022-08-29', y: 363 },
            { x: '2022-08-30', y: 20458 },
            { x: '2022-08-30', y: 20458 },
            { x: '2022-09-01', y: 3388 },
            { x: '2022-09-01', y: 3388 },
            { x: '2022-09-02', y: 0 },
            { x: '2022-09-02', y: 165 },
            { x: '2022-09-03', y: 4650 },
            { x: '2022-09-03', y: 4650 },
            { x: '2022-09-04', y: 4800 },
            { x: '2022-09-04', y: 4800 },
            { x: '2022-09-05', y: 1302 },
            { x: '2022-09-06', y: 0 },
            { x: '2022-09-07', y: 825 },
            { x: '2022-09-10', y: 229 },
            { x: '2022-09-11', y: 1516 },
            { x: '2022-09-11', y: 1516 },
            { x: '2022-09-12', y: 2105 },
            { x: '2022-09-13', y: 5055 },
            { x: '2022-09-14', y: 3002 },
            { x: '2022-09-16', y: 3204 },
            { x: '2022-09-17', y: 2448 },
            { x: '2022-09-18', y: 1625 },
            { x: '2022-09-19', y: 1454 },
            { x: '2022-09-20', y: 3770 },
            { x: '2022-09-21', y: 1795 },
            { x: '2022-09-23', y: 15088 },
            { x: '2022-09-24', y: 5453 },
            { x: '2022-09-25', y: 993 },
            { x: '2022-09-26', y: 4923 },
            { x: '2022-09-26', y: 4923 },
            { x: '2022-09-28', y: 578 },
            { x: '2022-09-28', y: 578 },
            { x: '2022-09-29', y: 3164 },
            { x: '2022-09-29', y: 3164 },
            { x: '2022-09-30', y: 0 },
            { x: '2022-09-30', y: 824 },
            { x: '2022-10-01', y: 6337 },
            { x: '2022-10-01', y: 6337 },
            { x: '2022-10-02', y: 814 },
            { x: '2022-10-02', y: 814 },
            { x: '2022-10-03', y: 975 },
            { x: '2022-10-04', y: 4772 },
            { x: '2022-10-05', y: 293 },
            { x: '2022-10-07', y: 10427 },
            { x: '2022-10-08', y: 873 },
            { x: '2022-10-09', y: 0 },
            { x: '2022-10-10', y: 8727 },
            { x: '2022-10-11', y: 11091 },
            { x: '2022-10-12', y: 1899 },
            { x: '2022-10-14', y: 14134 },
            { x: '2022-10-15', y: 2771 },
            { x: '2022-10-16', y: 2211 },
            { x: '2022-10-17', y: 0 },
            { x: '2022-10-18', y: 0 },
            { x: '2022-10-20', y: 6928 },
            { x: '2022-10-21', y: 0 },
            { x: '2022-10-22', y: 551 },
            { x: '2022-10-23', y: 5740 },
            { x: '2022-10-24', y: 1570 },
            { x: '2022-10-25', y: 1461 },
            { x: '2022-10-26', y: 1664 },
            { x: '2022-10-28', y: 1815 },
            { x: '2022-10-29', y: 19700 },
            { x: '2022-10-30', y: 1519 },
            { x: '2022-10-31', y: 948 },
            { x: '2022-10-31', y: 948 },
            { x: '2022-11-01', y: 232 },
            { x: '2022-11-01', y: 232 },
            { x: '2022-11-03', y: 121 },
            { x: '2022-11-03', y: 121 },
            { x: '2022-11-04', y: 15353 },
            { x: '2022-11-04', y: 15353 },
            { x: '2022-11-05', y: 1495 },
            { x: '2022-11-05', y: 1495 },
            { x: '2022-11-06', y: 5860 },
            { x: '2022-11-06', y: 5860 },
            { x: '2022-11-07', y: 11687 },
            { x: '2022-11-08', y: 0 },
            { x: '2022-11-10', y: 459 },
            { x: '2022-11-11', y: 6985 },
            { x: '2022-11-12', y: 620 },
            { x: '2022-11-13', y: 0 },
            { x: '2022-11-14', y: 0 },
            { x: '2022-11-15', y: 0 },
            { x: '2022-11-17', y: 4313 },
            { x: '2022-11-18', y: 1994 },
            { x: '2022-11-19', y: 1397 },
            { x: '2022-11-20', y: 0 },
            { x: '2022-11-20', y: 3193 },
            { x: '2022-11-21', y: 164468 },
            { x: '2022-11-22', y: 5975 },
            { x: '2022-11-23', y: 581 },
            { x: '2022-11-24', y: 3679 },
            { x: '2022-11-25', y: 0 },
            { x: '2022-11-26', y: 141 },
            { x: '2022-11-27', y: 13585 },
            { x: '2022-11-27', y: 13585 },
            { x: '2022-11-28', y: 111 },
            { x: '2022-11-28', y: 111 },
            { x: '2022-11-29', y: 3372 },
            { x: '2022-11-29', y: 3372 },
            { x: '2022-11-30', y: 0 },
            { x: '2022-11-30', y: 2472 },
            { x: '2022-12-01', y: 13163 },
            { x: '2022-12-01', y: 13163 },
            { x: '2022-12-02', y: 164100 },
            { x: '2022-12-02', y: 164100 },
            { x: '2022-12-03', y: 1404 },
            { x: '2022-12-03', y: 1404 },
            { x: '2022-12-04', y: 951 },
            { x: '2022-12-04', y: 951 },
            { x: '2022-12-05', y: 2024 },
            { x: '2022-12-06', y: 1993 },
            { x: '2022-12-08', y: 5952 },
            { x: '2022-12-09', y: 33253 },
            { x: '2022-12-10', y: 6058 },
            { x: '2022-12-11', y: 11672 },
            { x: '2022-12-12', y: 1749 },
            { x: '2022-12-14', y: 4124 },
            { x: '2022-12-15', y: 610 },
            { x: '2022-12-17', y: 5636 },
            { x: '2022-12-19', y: 613 },
            { x: '2022-12-20', y: 0 },
            { x: '2022-12-22', y: 5237 },
            { x: '2022-12-23', y: 0 },
            { x: '2022-12-24', y: 1107 },
            { x: '2022-12-25', y: 1540 },
            { x: '2022-12-26', y: 1216 },
            { x: '2022-12-27', y: 0 },
            { x: '2022-12-28', y: 453 },
            { x: '2022-12-30', y: 2203 },
            { x: '2023-01-01', y: 21323 },
        ],
        turnover: [
            { x: '2021-12-27', y: 2163040 },
            { x: '2021-12-28', y: 2216732 },
            { x: '2021-12-30', y: 2270433 },
            { x: '2022-01-03', y: 1507188 },
            { x: '2022-01-04', y: 2088842 },
            { x: '2022-01-05', y: 1863164 },
            { x: '2022-01-07', y: 1554374 },
            { x: '2022-01-08', y: 3996667 },
            { x: '2022-01-09', y: 2107512 },
            { x: '2022-01-10', y: 1968818 },
            { x: '2022-01-11', y: 2111593 },
            { x: '2022-01-13', y: 1597452 },
            { x: '2022-01-14', y: 1717372 },
            { x: '2022-01-15', y: 3963564 },
            { x: '2022-01-16', y: 2067826 },
            { x: '2022-01-17', y: 1586150 },
            { x: '2022-01-18', y: 2182779 },
            { x: '2022-01-20', y: 1908896 },
            { x: '2022-01-21', y: 1864796 },
            { x: '2022-01-22', y: 4296972 },
            { x: '2022-01-23', y: 2337850 },
            { x: '2022-01-24', y: 1405775 },
            { x: '2022-01-26', y: 2140596 },
            { x: '2022-01-27', y: 1882103 },
            { x: '2022-01-28', y: 1584579 },
            { x: '2022-01-29', y: 4031008 },
            { x: '2022-01-30', y: 1822074 },
            { x: '2022-01-31', y: 1533027 },
            { x: '2022-01-31', y: 1533027 },
            { x: '2022-02-02', y: 2221927 },
            { x: '2022-02-02', y: 2221927 },
            { x: '2022-02-03', y: 1803017 },
            { x: '2022-02-03', y: 1803017 },
            { x: '2022-02-04', y: 2076974 },
            { x: '2022-02-04', y: 2076974 },
            { x: '2022-02-05', y: 3898946 },
            { x: '2022-02-05', y: 3898946 },
            { x: '2022-02-06', y: 2123941 },
            { x: '2022-02-06', y: 2123941 },
            { x: '2022-02-07', y: 1816725 },
            { x: '2022-02-08', y: 2821553 },
            { x: '2022-02-10', y: 1729338 },
            { x: '2022-02-11', y: 1844542 },
            { x: '2022-02-12', y: 3303241 },
            { x: '2022-02-13', y: 2219943 },
            { x: '2022-02-14', y: 1660515 },
            { x: '2022-02-15', y: 2668952 },
            { x: '2022-02-16', y: 1762234 },
            { x: '2022-02-18', y: 1980833 },
            { x: '2022-02-19', y: 4034683 },
            { x: '2022-02-20', y: 2179701 },
            { x: '2022-02-21', y: 1655116 },
            { x: '2022-02-22', y: 2218744 },
            { x: '2022-02-24', y: 2321546 },
            { x: '2022-02-25', y: 1924305 },
            { x: '2022-02-26', y: 3449826 },
            { x: '2022-02-27', y: 2454191 },
            { x: '2022-02-28', y: 1747606 },
            { x: '2022-02-28', y: 1747606 },
            { x: '2022-03-01', y: 2630161 },
            { x: '2022-03-01', y: 2630161 },
            { x: '2022-03-03', y: 1718022 },
            { x: '2022-03-03', y: 1718022 },
            { x: '2022-03-04', y: 1934188 },
            { x: '2022-03-04', y: 1934188 },
            { x: '2022-03-05', y: 4480603 },
            { x: '2022-03-05', y: 4480603 },
            { x: '2022-03-06', y: 2550781 },
            { x: '2022-03-06', y: 2550781 },
            { x: '2022-03-07', y: 1857590 },
            { x: '2022-03-08', y: 2764184 },
            { x: '2022-03-09', y: 1996495 },
            { x: '2022-03-11', y: 1738502 },
            { x: '2022-03-12', y: 4086491 },
            { x: '2022-03-13', y: 2291779 },
            { x: '2022-03-14', y: 1824110 },
            { x: '2022-03-15', y: 2530534 },
            { x: '2022-03-17', y: 2062551 },
            { x: '2022-03-18', y: 1744067 },
            { x: '2022-03-19', y: 4003427 },
            { x: '2022-03-20', y: 2245953 },
            { x: '2022-03-21', y: 1115849 },
            { x: '2022-03-22', y: 1435368 },
            { x: '2022-03-23', y: 1041459 },
            { x: '2022-03-25', y: 1470645 },
            { x: '2022-03-26', y: 2662094 },
            { x: '2022-03-27', y: 1439446 },
            { x: '2022-03-28', y: 1073934 },
            { x: '2022-03-28', y: 1073934 },
            { x: '2022-03-30', y: 996161 },
            { x: '2022-03-30', y: 996161 },
            { x: '2022-03-31', y: 770418 },
            { x: '2022-03-31', y: 770418 },
            { x: '2022-04-01', y: 1318460 },
            { x: '2022-04-01', y: 1318460 },
            { x: '2022-04-02', y: 3320684 },
            { x: '2022-04-02', y: 3320684 },
            { x: '2022-04-03', y: 2153438 },
            { x: '2022-04-03', y: 2153438 },
            { x: '2022-04-04', y: 2026629 },
            { x: '2022-04-06', y: 3340465 },
            { x: '2022-04-07', y: 2391918 },
            { x: '2022-04-08', y: 1640861 },
            { x: '2022-04-09', y: 4151417 },
            { x: '2022-04-10', y: 2477837 },
            { x: '2022-04-11', y: 1991800 },
            { x: '2022-04-12', y: 1769109 },
            { x: '2022-04-13', y: 1555720 },
            { x: '2022-04-15', y: 1731650 },
            { x: '2022-04-16', y: 3627341 },
            { x: '2022-04-17', y: 2010871 },
            { x: '2022-04-18', y: 2065519 },
            { x: '2022-04-19', y: 2364184 },
            { x: '2022-04-21', y: 2919774 },
            { x: '2022-04-22', y: 1427181 },
            { x: '2022-04-23', y: 3638864 },
            { x: '2022-04-24', y: 2144627 },
            { x: '2022-04-25', y: 3406049 },
            { x: '2022-04-25', y: 3406049 },
            { x: '2022-04-27', y: 2066665 },
            { x: '2022-04-27', y: 2066665 },
            { x: '2022-04-28', y: 2305602 },
            { x: '2022-04-28', y: 2305602 },
            { x: '2022-04-29', y: 1584721 },
            { x: '2022-04-29', y: 1584721 },
            { x: '2022-04-30', y: 3299388 },
            { x: '2022-04-30', y: 3299388 },
            { x: '2022-05-01', y: 2548732 },
            { x: '2022-05-01', y: 2548732 },
            { x: '2022-05-02', y: 3549044 },
            { x: '2022-05-03', y: 1800344 },
            { x: '2022-05-04', y: 1646456 },
            { x: '2022-05-06', y: 1823595 },
            { x: '2022-05-07', y: 3382990 },
            { x: '2022-05-08', y: 2187832 },
            { x: '2022-05-09', y: 3135183 },
            { x: '2022-05-10', y: 2078728 },
            { x: '2022-05-12', y: 1838428 },
            { x: '2022-05-13', y: 1477816 },
            { x: '2022-05-14', y: 3243468 },
            { x: '2022-05-15', y: 2137933 },
            { x: '2022-05-16', y: 1817522 },
            { x: '2022-05-17', y: 1412737 },
            { x: '2022-05-19', y: 1742480 },
            { x: '2022-05-20', y: 1611058 },
            { x: '2022-05-21', y: 1876607 },
            { x: '2022-05-22', y: 2666725 },
            { x: '2022-05-23', y: 2102020 },
            { x: '2022-05-24', y: 1209869 },
            { x: '2022-05-26', y: 1549892 },
            { x: '2022-05-27', y: 1481902 },
            { x: '2022-05-28', y: 2672439 },
            { x: '2022-05-29', y: 1828746 },
            { x: '2022-05-30', y: 1482171 },
            { x: '2022-05-30', y: 1482171 },
            { x: '2022-05-31', y: 1132856 },
            { x: '2022-05-31', y: 1132856 },
            { x: '2022-06-01', y: 1418450 },
            { x: '2022-06-01', y: 1418450 },
            { x: '2022-06-03', y: 1872963 },
            { x: '2022-06-03', y: 1872963 },
            { x: '2022-06-04', y: 2384361 },
            { x: '2022-06-04', y: 2384361 },
            { x: '2022-06-05', y: 1754995 },
            { x: '2022-06-05', y: 1754995 },
            { x: '2022-06-06', y: 2071185 },
            { x: '2022-06-07', y: 1823096 },
            { x: '2022-06-08', y: 1797240 },
            { x: '2022-06-10', y: 1696421 },
            { x: '2022-06-11', y: 2407785 },
            { x: '2022-06-12', y: 2054648 },
            { x: '2022-06-13', y: 1569162 },
            { x: '2022-06-15', y: 1168611 },
            { x: '2022-06-16', y: 1116838 },
            { x: '2022-06-17', y: 1133023 },
            { x: '2022-06-18', y: 2182313 },
            { x: '2022-06-19', y: 1376030 },
            { x: '2022-06-20', y: 1250869 },
            { x: '2022-06-21', y: 1166146 },
            { x: '2022-06-22', y: 1368130 },
            { x: '2022-06-24', y: 1372037 },
            { x: '2022-06-25', y: 1221789 },
            { x: '2022-06-25', y: 1221789 },
            { x: '2022-06-26', y: 2270186 },
            { x: '2022-06-27', y: 2925570 },
            { x: '2022-06-27', y: 2925570 },
            { x: '2022-06-29', y: 1437612 },
            { x: '2022-06-29', y: 1437612 },
            { x: '2022-06-30', y: 1332433 },
            { x: '2022-06-30', y: 1332433 },
            { x: '2022-07-01', y: 1498568 },
            { x: '2022-07-01', y: 1498568 },
            { x: '2022-07-02', y: 2256746 },
            { x: '2022-07-02', y: 2256746 },
            { x: '2022-07-03', y: 2089325 },
            { x: '2022-07-03', y: 2089325 },
            { x: '2022-07-04', y: 1501603 },
            { x: '2022-07-06', y: 925513 },
            { x: '2022-07-06', y: 925513 },
            { x: '2022-07-07', y: 1349648 },
            { x: '2022-07-08', y: 1226594 },
            { x: '2022-07-09', y: 2189195 },
            { x: '2022-07-10', y: 1729698 },
            { x: '2022-07-11', y: 1759242 },
            { x: '2022-07-11', y: 1759242 },
            { x: '2022-07-13', y: 1219341 },
            { x: '2022-07-14', y: 1422583 },
            { x: '2022-07-15', y: 918814 },
            { x: '2022-07-15', y: 918814 },
            { x: '2022-07-16', y: 2014277 },
            { x: '2022-07-17', y: 1858934 },
            { x: '2022-07-18', y: 1850659 },
            { x: '2022-07-19', y: 1641754 },
            { x: '2022-07-20', y: 1754395 },
            { x: '2022-07-22', y: 1794610 },
            { x: '2022-07-23', y: 2227370 },
            { x: '2022-07-24', y: 1738523 },
            { x: '2022-07-25', y: 2785561 },
            { x: '2022-07-26', y: 2484554 },
            { x: '2022-07-27', y: 2640418 },
            { x: '2022-07-29', y: 1452300 },
            { x: '2022-07-30', y: 2499401 },
            { x: '2022-07-31', y: 1852626 },
            { x: '2022-08-01', y: 2118280 },
            { x: '2022-08-02', y: 1900355 },
            { x: '2022-08-03', y: 2136501 },
            { x: '2022-08-05', y: 2131118 },
            { x: '2022-08-06', y: 3326868 },
            { x: '2022-08-07', y: 2368469 },
            { x: '2022-08-08', y: 3147561 },
            { x: '2022-08-09', y: 2812547 },
            { x: '2022-08-10', y: 2072484 },
            { x: '2022-08-12', y: 1837342 },
            { x: '2022-08-13', y: 3481183 },
            { x: '2022-08-14', y: 2285288 },
            { x: '2022-08-15', y: 2331666 },
            { x: '2022-08-16', y: 2837288 },
            { x: '2022-08-17', y: 1926507 },
            { x: '2022-08-19', y: 1784864 },
            { x: '2022-08-20', y: 3809389 },
            { x: '2022-08-21', y: 2236727 },
            { x: '2022-08-22', y: 2931095 },
            { x: '2022-08-23', y: 1723582 },
            { x: '2022-08-24', y: 1641009 },
            { x: '2022-08-26', y: 2204893 },
            { x: '2022-08-27', y: 4220655 },
            { x: '2022-08-28', y: 2847737 },
            { x: '2022-08-29', y: 2136192 },
            { x: '2022-08-29', y: 2136192 },
            { x: '2022-08-30', y: 3039524 },
            { x: '2022-08-30', y: 3039524 },
            { x: '2022-09-01', y: 2313670 },
            { x: '2022-09-01', y: 2313670 },
            { x: '2022-09-02', y: 1986610 },
            { x: '2022-09-02', y: 1986610 },
            { x: '2022-09-03', y: 3939953 },
            { x: '2022-09-03', y: 3939953 },
            { x: '2022-09-04', y: 2530683 },
            { x: '2022-09-04', y: 2530683 },
            { x: '2022-09-05', y: 3495692 },
            { x: '2022-09-06', y: 3577098 },
            { x: '2022-09-07', y: 3651240 },
            { x: '2022-09-10', y: 2770743 },
            { x: '2022-09-11', y: 647640 },
            { x: '2022-09-11', y: 647640 },
            { x: '2022-09-12', y: 2696315 },
            { x: '2022-09-13', y: 3250085 },
            { x: '2022-09-14', y: 3349304 },
            { x: '2022-09-16', y: 1840410 },
            { x: '2022-09-17', y: 3316178 },
            { x: '2022-09-18', y: 2266794 },
            { x: '2022-09-19', y: 1492371 },
            { x: '2022-09-20', y: 1195822 },
            { x: '2022-09-21', y: 1136083 },
            { x: '2022-09-23', y: 1896835 },
            { x: '2022-09-24', y: 2835644 },
            { x: '2022-09-25', y: 1757135 },
            { x: '2022-09-26', y: 1983510 },
            { x: '2022-09-26', y: 1983510 },
            { x: '2022-09-28', y: 1050092 },
            { x: '2022-09-28', y: 1050092 },
            { x: '2022-09-29', y: 917605 },
            { x: '2022-09-29', y: 917605 },
            { x: '2022-09-30', y: 1464980 },
            { x: '2022-09-30', y: 1464980 },
            { x: '2022-10-01', y: 3467621 },
            { x: '2022-10-01', y: 3467621 },
            { x: '2022-10-02', y: 2515919 },
            { x: '2022-10-02', y: 2515919 },
            { x: '2022-10-03', y: 2997298 },
            { x: '2022-10-04', y: 3286405 },
            { x: '2022-10-05', y: 3310050 },
            { x: '2022-10-07', y: 1638636 },
            { x: '2022-10-08', y: 3719057 },
            { x: '2022-10-09', y: 2347911 },
            { x: '2022-10-10', y: 2610115 },
            { x: '2022-10-11', y: 2931239 },
            { x: '2022-10-12', y: 3323129 },
            { x: '2022-10-14', y: 1473996 },
            { x: '2022-10-15', y: 3456182 },
            { x: '2022-10-16', y: 2536535 },
            { x: '2022-10-17', y: 1730183 },
            { x: '2022-10-18', y: 2634473 },
            { x: '2022-10-20', y: 2246915 },
            { x: '2022-10-21', y: 1617413 },
            { x: '2022-10-22', y: 3569594 },
            { x: '2022-10-23', y: 2312744 },
            { x: '2022-10-24', y: 2241646 },
            { x: '2022-10-25', y: 2934880 },
            { x: '2022-10-26', y: 2969093 },
            { x: '2022-10-28', y: 1638857 },
            { x: '2022-10-29', y: 3433548 },
            { x: '2022-10-30', y: 2305732 },
            { x: '2022-10-31', y: 1696744 },
            { x: '2022-10-31', y: 1696744 },
            { x: '2022-11-01', y: 3964560 },
            { x: '2022-11-01', y: 3964560 },
            { x: '2022-11-03', y: 2343374 },
            { x: '2022-11-03', y: 2343374 },
            { x: '2022-11-04', y: 1754659 },
            { x: '2022-11-04', y: 1754659 },
            { x: '2022-11-05', y: 3274148 },
            { x: '2022-11-05', y: 3274148 },
            { x: '2022-11-06', y: 2503151 },
            { x: '2022-11-06', y: 2503151 },
            { x: '2022-11-07', y: 1602915 },
            { x: '2022-11-08', y: 2901258 },
            { x: '2022-11-10', y: 1863031 },
            { x: '2022-11-11', y: 1806240 },
            { x: '2022-11-12', y: 2156676 },
            { x: '2022-11-13', y: 2265945 },
            { x: '2022-11-14', y: 1341994 },
            { x: '2022-11-15', y: 1237603 },
            { x: '2022-11-17', y: 1189209 },
            { x: '2022-11-18', y: 1265185 },
            { x: '2022-11-19', y: 2232594 },
            { x: '2022-11-20', y: 866735 },
            { x: '2022-11-20', y: 866735 },
            { x: '2022-11-21', y: 1174773 },
            { x: '2022-11-22', y: 1049960 },
            { x: '2022-11-23', y: 1249905 },
            { x: '2022-11-24', y: 1277286 },
            { x: '2022-11-25', y: 1380586 },
            { x: '2022-11-26', y: 2186634 },
            { x: '2022-11-27', y: 1533223 },
            { x: '2022-11-27', y: 1533223 },
            { x: '2022-11-28', y: 1332474 },
            { x: '2022-11-28', y: 1332474 },
            { x: '2022-11-29', y: 1175630 },
            { x: '2022-11-29', y: 1175630 },
            { x: '2022-11-30', y: 1271385 },
            { x: '2022-11-30', y: 1271385 },
            { x: '2022-12-01', y: 1579631 },
            { x: '2022-12-01', y: 1579631 },
            { x: '2022-12-02', y: 1406575 },
            { x: '2022-12-02', y: 1406575 },
            { x: '2022-12-03', y: 2042424 },
            { x: '2022-12-03', y: 2042424 },
            { x: '2022-12-04', y: 1379886 },
            { x: '2022-12-04', y: 1379886 },
            { x: '2022-12-05', y: 1385599 },
            { x: '2022-12-06', y: 1059172 },
            { x: '2022-12-08', y: 1156442 },
            { x: '2022-12-09', y: 1282637 },
            { x: '2022-12-10', y: 2440768 },
            { x: '2022-12-11', y: 1133905 },
            { x: '2022-12-12', y: 1092375 },
            { x: '2022-12-14', y: 1219600 },
            { x: '2022-12-15', y: 979773 },
            { x: '2022-12-17', y: 2254430 },
            { x: '2022-12-19', y: 901453 },
            { x: '2022-12-20', y: 1082086 },
            { x: '2022-12-22', y: 1428982 },
            { x: '2022-12-23', y: 1216090 },
            { x: '2022-12-24', y: 928572 },
            { x: '2022-12-25', y: 946323 },
            { x: '2022-12-26', y: 3418605 },
            { x: '2022-12-27', y: 1846200 },
            { x: '2022-12-28', y: 1573635 },
            { x: '2022-12-30', y: 2856214 },
            { x: '2023-01-01', y: 1614513 },
        ]

    },
    {
        name: "Fullträff",
        operator: "Svenska Spel",
        payout: "75%",
        win1: "",
        win2: "30%",
        win3: "18%",
        win4: "22%",
        win5: "",
        fund: "30%",
        outcomes: "549 755 813 888",
        maxStake: "30 000 Kr",
        stakePerRow: "1 Kr",
        guarantee: "50 000 000 Kr",
        terms: "Garantin delas ut om någon får 13 rätt. 30% av prispengarna i varje omgång går till en fond för detta",
        numberOfGames: 13
    },
    {
        name: "Big 9",
        operator: "ATG",
        payout: "65%",
        win1: "40%",
        win2: "20%",
        win3: "40%",
        win4: "",
        win5: "",
        fund: "",
        outcomes: "1 679 616",
        maxStake: "",
        stakePerRow: "0.50 Kr",
        guarantee: "",
        terms: "",
        numberOfGames: 9,
        wins: [
            { x: '2022-01-02', y: 2054650 },
            { x: '2022-01-15', y: 2833 },
            { x: '2022-01-08', y: 11716 },
            { x: '2022-01-29', y: 450673 },
            { x: '2022-01-22', y: 0 },
            { x: '2022-02-05', y: 169528 },
            { x: '2022-02-19', y: 206156 },
            { x: '2022-02-26', y: 88008 },
            { x: '2022-02-12', y: 41674 },
            { x: '2022-03-26', y: 17278 },
            { x: '2022-03-05', y: 117498 },
            { x: '2022-03-12', y: 17987 },
            { x: '2022-03-19', y: 3200 },
            { x: '2022-04-02', y: 53164 },
            { x: '2022-04-30', y: 6519 },
            { x: '2022-04-09', y: 21121 },
            { x: '2022-04-16', y: 54719 },
            { x: '2022-04-23', y: 2892 },
            { x: '2022-05-07', y: 1692711 },
            { x: '2022-05-21', y: 0 },
            { x: '2022-06-04', y: 0 },
            { x: '2022-05-14', y: 0 },
            { x: '2022-05-28', y: 439472 },
            { x: '2022-06-25', y: 44976 },
            { x: '2022-06-11', y: 0 },
            { x: '2022-06-18', y: 813702 },
            { x: '2022-07-02', y: 0 },
            { x: '2022-07-09', y: 166926 },
            { x: '2022-07-30', y: 72671 },
            { x: '2022-07-16', y: 750 },
            { x: '2022-07-23', y: 89373 },
            { x: '2022-09-03', y: 111010 },
            { x: '2022-08-20', y: 3744 },
            { x: '2022-08-06', y: 71969 },
            { x: '2022-08-27', y: 8399 },
            { x: '2022-08-13', y: 224069 },
            { x: '2022-09-10', y: 6737 },
            { x: '2022-09-14', y: 145858 },
            { x: '2022-09-24', y: 401635 },
            { x: '2022-10-01', y: 43978 },
            { x: '2022-09-07', y: 166265 },
            { x: '2022-09-17', y: 37391 },
            { x: '2022-10-26', y: 56815 },
            { x: '2022-10-12', y: 26602 },
            { x: '2022-10-05', y: 3734 },
            { x: '2022-10-08', y: 7547 },
            { x: '2022-10-15', y: 13167 },
            { x: '2022-10-22', y: 11521 },
            { x: '2022-11-02', y: 910 },
            { x: '2022-10-29', y: 194205 },
            { x: '2022-11-05', y: 9722 },
            { x: '2022-11-30', y: 161919 },
            { x: '2022-11-27', y: 26193 },
            { x: '2022-11-21', y: 22511 },
            { x: '2022-11-24', y: 23743 },
            { x: '2022-11-12', y: 8671 },
            { x: '2022-12-26', y: 3058 },
            { x: '2022-12-31', y: 924181 },
            { x: '2022-12-03', y: 1069 },
            { x: '2022-12-09', y: 0 },
            { x: '2022-12-17', y: 132977 }
        ],
        turnover: [
            { x: '2022-01-02', y: 3522471 },
            { x: '2022-01-15', y: 2776141 },
            { x: '2022-01-08', y: 2921032 },
            { x: '2022-01-29', y: 4090846 },
            { x: '2022-01-22', y: 3533174 },
            { x: '2022-02-05', y: 6654072 },
            { x: '2022-02-19', y: 3171635 },
            { x: '2022-02-26', y: 3215693 },
            { x: '2022-02-12', y: 3411803 },
            { x: '2022-03-26', y: 3531629 },
            { x: '2022-03-05', y: 4226817 },
            { x: '2022-03-12', y: 2736190 },
            { x: '2022-03-19', y: 2965655 },
            { x: '2022-04-02', y: 3849028 },
            { x: '2022-04-30', y: 3374460 },
            { x: '2022-04-09', y: 2984509 },
            { x: '2022-04-16', y: 2504489 },
            { x: '2022-04-23', y: 2469942 },
            { x: '2022-05-07', y: 3135971 },
            { x: '2022-05-21', y: 2529342 },
            { x: '2022-06-04', y: 1910118 },
            { x: '2022-05-14', y: 2210963 },
            { x: '2022-05-28', y: 3574451 },
            { x: '2022-06-25', y: 1600119 },
            { x: '2022-06-11', y: 2436594 },
            { x: '2022-06-18', y: 1912543 },
            { x: '2022-07-02', y: 1500096 },
            { x: '2022-07-09', y: 1710025 },
            { x: '2022-07-30', y: 1292717 },
            { x: '2022-07-16', y: 1378377 },
            { x: '2022-07-23', y: 1556674 },
            { x: '2022-09-03', y: 3494058 },
            { x: '2022-08-20', y: 2033022 },
            { x: '2022-08-06', y: 2820508 },
            { x: '2022-08-27', y: 3097869 },
            { x: '2022-08-13', y: 1723611 },
            { x: '2022-09-10', y: 1767739 },
            { x: '2022-09-14', y: 1121987 },
            { x: '2022-09-24', y: 3102080 },
            { x: '2022-10-01', y: 2115289 },
            { x: '2022-09-07', y: 1278969 },
            { x: '2022-09-17', y: 1760772 },
            { x: '2022-10-26', y: 1092603 },
            { x: '2022-10-12', y: 1427396 },
            { x: '2022-10-05', y: 1007753 },
            { x: '2022-10-08', y: 1882324 },
            { x: '2022-10-15', y: 1782512 },
            { x: '2022-10-22', y: 2057075 },
            { x: '2022-11-02', y: 991099 },
            { x: '2022-10-29', y: 3053300 },
            { x: '2022-11-05', y: 2460459 },
            { x: '2022-11-30', y: 1245537 },
            { x: '2022-11-27', y: 2199312 },
            { x: '2022-11-21', y: 2058194 },
            { x: '2022-11-24', y: 1476100 },
            { x: '2022-11-12', y: 2319843 },
            { x: '2022-12-26', y: 1710463 },
            { x: '2022-12-31', y: 2688209 },
            { x: '2022-12-03', y: 1519866 },
            { x: '2022-12-09', y: 1190555 },
            { x: '2022-12-17', y: 1267995 },
        ]

    },
    {
        name: "Colossus Välj 15",
        operator: "Mr Green, Paf",
        payout: "70%",
        win1: "40%",
        win2: "10%",
        win3: "13%",
        win4: "17%",
        win5: "20%",
        fund: "",
        outcomes: "14 348 907",
        maxStake: "",
        stakePerRow: "2 Kr",
        guarantee: "800 000 Kr",
        terms: "Garantin gäller för insats på 2 Kr. Vid insats på 20 Kr per rad ökar garantin till 8 000 000 Kr",
        numberOfGames: 15
    }
];

