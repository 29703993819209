import { Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Typography } from '@mui/material';
import BannerColumn from './BannerColumn';
import React, { useEffect } from 'react';
import { getPoolXRounds } from '../common/BetslipApi';
import { Round } from '../model/poolx';

export default function SystemPage() {

  const [poolXRounds, setPoolXRounds] = React.useState<Round[]>([]);

  useEffect(() => {
    getPoolXRounds(setPoolXRounds);
  }, []);

  const handleChange = (event: SelectChangeEvent<unknown>) => {

  }

  return (
    <Container maxWidth='xl' sx={{ mt: 4, mb: 4 }}>
      <Grid container justifyContent="center" id="coupons" direction="row">

        <Grid item xs={12} sm={9} sx={{ marginTop: '25px' }}>
          <Grid container spacing={3} id="coupons" direction="column">
            {
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
                <Typography variant="h1" sx={{ fontSize: '1.5rem', fontWeight: 400 }}>Skapa system</Typography>
              </Paper>
            }

            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', marginRight: '20px', marginTop: '20px' }}>              
              <FormControl>
                <InputLabel id="select-label">Välj runda</InputLabel>
                <Select
                  labelId="select-label"
                  id="select"
                  label="Runda"
                  onChange={(event) => handleChange(event)}
                >
                  {
                    poolXRounds.map((round, index) =>
                      <MenuItem value={round.id}>{round.name + " - " + round.deadline}</MenuItem>
                    )
                  }
                </Select>
              </FormControl>
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <BannerColumn />
        </Grid>
      </Grid>
    </Container>
  );
}



