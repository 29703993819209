import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline, Grid, Toolbar} from '@mui/material';
import './/App.css';
import { useCookies } from 'react-cookie';
import { addCouponToApi, deleteCouponFromApi, getCoupons, loginUser } from "./common/BetslipApi";
import TopMenu from "./components/TopMenu";
import Footer from "./components/Footer";
import { useEffect } from "react";
import { GoogleOAuthProvider,CredentialResponse } from '@react-oauth/google';
import { gapi } from 'gapi-script';
import Terms from "./components/Terms";
import Report from "./components/Report";
import Pools from "./components/Pools";
import PoolX from "./components/PoolX";
import Live from "./components/Live";
import LiveCoupon from "./components/LiveCoupon";
import { Helmet } from "react-helmet";
import Syndicates from "./components/Syndicates";
import BlogPage from "./components/BlogPage";
import BlogDetail from "./components/BlogDetail";
import { jwtDecode } from "jwt-decode";
import Odds from "./components/Odds";
import useAppBarHeight from "./common/appBarHeight";
import { BlogFilter, BlogStatus } from "./model/blog";
import SyndicatePage from "./components/SyndicatePage";
import SystemPage from "./components/SystemPage";

function App() {

  const clientId = '895883489977-0rb23654p5qt0vrbnm60ibofik10d29h.apps.googleusercontent.com';

  const [cookies, setCookie] = useCookies(["coupons", "user"]);
  const [darkMode, setDarkMode] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [profile, setProfile] = useState([]);
  const [selected, setSelected] = useState("Home");

  const appBarHeight = useAppBarHeight()-65;

  const [coupons, setCoupons] = React.useState([]);

  useEffect(() => {

    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: ''
      });
    };
    gapi.load('client:auth2', initClient);

    const timer = setInterval(() => {
      getCoupons(setCoupons, cookies.coupons, setFetching, profile?.googleId)
    }, 60000);
    return () => {
      clearInterval(timer);
    };
  }, [cookies.coupons, coupons, profile?.googleId]);

  if (!fetching) {
    getCoupons(setCoupons, cookies.coupons, setFetching, profile?.googleId);
  }
  

  let mdTheme = createTheme({
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        'Jaldi',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        light: "#99A6B2",
        main: "#603F83FF",
      },
      secondary: {
        main: "#C7D3D4FF",
      },
    }
  });

  function addCoupon(coupon, profile) {
    addCouponToApi(coupon, cookies, setCookie);
    setTimeout(() => { refreshCoupons(profile); }, 1000);
  }

  function removeCoupon(id, profile) {
    deleteCouponFromApi(id, cookies, setCookie);
    setTimeout(() => { refreshCoupons(profile); }, 1000);
  }

  function refreshCoupons(profile) {
    getCoupons(setCoupons, cookies.coupons, setFetching, profile?.googleId);
  }

  const onSuccess = (res) => {

    const decoded = jwtDecode(res.credential);

    let profile = 
    {
      Name:decoded.name,
      FamilyName:decoded.family_name,
      GivenName: decoded.given_name,
      EMail: decoded.email,
      GoogleId: res.credential,
      ImageUrl: decoded.picture
  }
    setProfile(profile);
    loginUser(profile, refreshCoupons);
  };
  const onFailure = (err) => {
    console.log('failed:', err);
  };
  const logOutCallback = () => {
    setProfile(null);
    refreshCoupons(null);
  };

  return (

    <Router>
      <GoogleOAuthProvider clientId={clientId}>
      <ThemeProvider theme={mdTheme}>
        <CssBaseline />
        <Grid container direction="column">
          <TopMenu onSuccess={onSuccess} onFailure={onFailure} clientId={clientId} logOut={logOutCallback} profile={profile} selected={selected} setSelected={setSelected}/>         
          <Box sx={{minHeight:appBarHeight + "px"}} />
          <Box                    
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh-' + appBarHeight + "px",
              overflow: 'auto',      
              
            }}
          >
            <Helmet>
              <title>Spelvinnare.se - Oddsjämförelse, Liverättning, PoolX, Tips</title>
              <meta name="description" content="Bästa speltipsen och länkar till poolspel på Trav och fotboll. Tipsen skrivs av våra spelvinnare Ola Thimrén, Hans Petersson och Matte Forslund." />
              <meta property="og:url" content="https://www.spelvinnare.se/"></meta>
              <meta property="twitter:url" content="https://www.spelvinnare.se/"></meta>              
            </Helmet>
            <Toolbar />
            <Routes>
              <Route path="/draft" element={<BlogPage blogStatus={BlogStatus.DRAFT} filter={BlogFilter.ALL}/>} />
              <Route path="/blog" element={<BlogPage blogStatus={BlogStatus.PUBLISHED} filter={BlogFilter.ALL}/>} />
              <Route path="/andelsspel" element={<BlogPage blogStatus={BlogStatus.PUBLISHED} filter={BlogFilter.POOL}/>} />
              <Route path="/travsystem" element={<BlogPage blogStatus={BlogStatus.PUBLISHED} filter={BlogFilter.TRAV}/>} />
              <Route path="/andelar" element={<SyndicatePage blogStatus={BlogStatus.PUBLISHED} filter={BlogFilter.ALL}/>} />
              <Route path="/andelar_trav" element={<SyndicatePage blogStatus={BlogStatus.PUBLISHED} filter={BlogFilter.TRAV}/>} />
              <Route path="/andelar_sport" element={<SyndicatePage blogStatus={BlogStatus.PUBLISHED} filter={BlogFilter.POOL}/>} />
              <Route path="/sport" element={<BlogPage blogStatus={BlogStatus.PUBLISHED} filter={BlogFilter.SPORT}/>} />
              <Route path="/blog/:blogPostId" element={<BlogDetail/>} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/report" element={<Report profile={profile} removeCoupon={removeCoupon} />} />
              <Route path="/pools" element={<Pools />} />
              <Route path="/poolx" element={<PoolX />} />
              <Route path="/system" element={<SystemPage />} />
              <Route path="/syndicates" element={<Syndicates profile={profile} />} />
              <Route path="/live" element={<Live profile={profile} />} />
              <Route path="/live/:id" element={<LiveCoupon />} />
              <Route path="/odds" element={<Odds coupons={coupons} removeCoupon={removeCoupon} profile={profile} addCoupon={addCoupon} />} />
              <Route path="/fixtures" element={<Odds debug={true} coupons={coupons} removeCoupon={removeCoupon} profile={profile} addCoupon={addCoupon} />} />              
              <Route path="/" element={<Home coupons={coupons} removeCoupon={removeCoupon} profile={profile} addCoupon={addCoupon} />} />              
            </Routes>
            <Footer darkMode={darkMode} setDarkMode={setDarkMode} theme={mdTheme} />
          </Box>
        </Grid>
      </ThemeProvider>
      </GoogleOAuthProvider>
    </Router>
  );
}

export default App;
