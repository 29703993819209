import { Box, Button, Card, Grid, Link, SxProps, Theme, Typography } from "@mui/material";
import { Syndicate } from "../model/syndicate";
import svenska from "../images/svenskaspel.png";
import atg from "../images/atg.png";
import dbet from "../images/Dbet.jpg";

function SyndicateItem({ syndicate }: { syndicate: Syndicate }) {

    let logoImage = "";
    let width = "68.88px";
    let height = "20px";

    const title: SxProps<Theme> = {
        fontSize: "12px",
        fontWeight: "bold",
        marginRight: "5px"
    }

    const text: SxProps<Theme> = {
        fontSize: "12px"
    }

    let isAtg = false;

    if (syndicate.url.indexOf("svenskaspel") > -1) {
        logoImage = `url(${svenska})`;
    }
    else if (syndicate.url.indexOf("atg") > -1) {
        logoImage = `url(${atg})`;
        width = "60px";
        isAtg = true;
        syndicate.url = syndicate.url.replace('spel//', 'spel/');
    }

    else if (syndicate.url.indexOf("dbet") > -1) {
        logoImage = `url(${dbet})`;
        width = "60px";
    }

    let deadline = new Date(syndicate.deadline);
    let formatDate = new Intl.DateTimeFormat('sv-SV', {
        weekday: 'long',   // Full day name, e.g., "Monday"
        hour: '2-digit',   // Two-digit hour, e.g., "19"
        minute: '2-digit', // Two-digit minute, e.g., "00"
        
        
        hour12: false      // 24-hour format, set to true for 12-hour format
    })

    const getName = (intName: string, url: string) => {
        let extName = intName;
        if (syndicate.url.indexOf("dbet") > -1) {
            switch (intName.toLowerCase()) {
                case "flextipset":
                    extName = "Super 8";
                    break;
                case "mixtipset":
                    extName = "Super 12";
                    break;
                case "maxtipset":
                    extName = "Super 13";
                    break;
                default:
                    break;
            }
        }
        return extName;
    }

    return (
        <Grid direction="column" alignItems="center" width="100%">
            <Card elevation={7} sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.secondary.main
                        : theme.palette.grey[900],

                maxWidth: "500px",
                minWidth: "500px",
                padding: "20px"
            }}>

                <Grid container direction="column" alignItems="center">
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Typography sx={{ fontSize: "14px", fontWeight: "bold", marginBottom: "10px" }}>{syndicate.name}</Typography>
                    </Grid>
                    <Grid container direction="row" alignItems="start" justifyContent="space-between">
                        <Box sx={{ width: { width }, height: { height }, backgroundSize: "cover", backgroundImage: logoImage }}></Box>
                        <Grid item width="170px">
                            <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                                <Grid container direction="row" justifyContent="flex-start" alignItems="space-between">
                                    <Typography sx={title}>{getName(syndicate.syndicateType, syndicate.url)} </Typography>
                                    <Typography sx={text}>{formatDate.format(deadline)}</Typography>
                                </Grid>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="space-between">
                                    <Typography sx={title}>Insats: </Typography>
                                    <Typography sx={text}>{Math.round(syndicate.sharePrice)} kr</Typography>
                                </Grid>
                                {!isAtg && <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Typography sx={title}>Sålda: </Typography>
                                    <Typography sx={text}>{syndicate.numSharesSold}</Typography>
                                </Grid>
                                }
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Typography sx={title}>Andelar: </Typography>
                                    <Typography sx={text}>{syndicate.totalShares}</Typography>
                                </Grid>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                                    <Typography sx={title}>Spelvinnare: </Typography>
                                    <Typography sx={text}>{syndicate.creator}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                <Link href={syndicate.url} target="_blank">
                                    <Button variant="contained" sx={{ margin: "10px" }}>
                                        Spela Här
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography sx={{ fontSize: '10px', color: 'gray' }}>
                        18+ Spela ansvarsfullt <a href="https://stodlinjen.se/">Stödlinjen</a>
                    </Typography>
                </Grid>
            </Card>
        </Grid>
    );
}

export default SyndicateItem;