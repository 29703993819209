import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Syndicate, SyndicateResponse } from '../model/syndicate';
import { getSyndicates } from '../common/BetslipApi';
import SyndicateItem from './SyndicateItem';
import { BlogFilter } from '../model/blog';

export default function SyndicateGrid({ numPosts, filter }:
  { numPosts: number, filter: BlogFilter }) {

  let emptySyndicates: SyndicateResponse = { agents: [] };

  let [response, setResponse] = useState(emptySyndicates);

  useEffect(() => {
    getSyndicates(setResponse);
  }, []);

  if (response.agents.length === 0) {
    return <></>;
  }

  let allSyndicates: Syndicate[] = []

  for (let index = 0; index < response.agents.length; index++) {

    if (filter === BlogFilter.POOL && ((response.agents[index].marketPlaceName.toLowerCase().indexOf("svenska") === -1 )&&(response.agents[index].marketPlaceName.toLowerCase().indexOf("dbet") === -1)))
      continue;

    if (filter === BlogFilter.TRAV && response.agents[index].marketPlaceName.toLowerCase().indexOf("atg") === -1)
      continue;

    response.agents[index].syndicate.forEach(element => allSyndicates.push(element));
  }

  let syndicatesToShow = allSyndicates.filter(p => new Date(p.deadline) > new Date());
  syndicatesToShow = syndicatesToShow.filter(p => p.numSharesSold < p.totalShares);

  syndicatesToShow = syndicatesToShow.filter(p => p.creator.toLowerCase() !== "kristian huselius");	
  
  const removeTwoMinutes = (date: Date) => {
    const updatedDate = new Date(date); // Create a copy of the date
    updatedDate.setMinutes(updatedDate.getMinutes() - 2); // Subtract 2 minutes
    return updatedDate;
  };

  syndicatesToShow = syndicatesToShow.sort((a, b) => {
    let aDate = new Date(a.deadline);
    let bDate = new Date(b.deadline);

    if(a.url.toLowerCase().indexOf("dbet") !== -1) {
      aDate = removeTwoMinutes(aDate);
    }
    if(b.url.toLowerCase().indexOf("dbet") !== -1) {
      aDate = removeTwoMinutes(aDate);
    }

    if(aDate < bDate)
      return -1;
    return 1;
  })
  syndicatesToShow = syndicatesToShow.splice(0, numPosts);

  return (
    <Grid container direction="column" spacing={2} sx={{ justifyContent: "center", alignItems: "center", padding: "20px" }}>
      {syndicatesToShow.map((syndicate, index) =>
        <Grid item key={"displayGrid" + index} sx={{ minWidth: '320px', maxWidth: '320px' }} >
          <SyndicateItem syndicate={syndicate} />
        </Grid>)
      }
    </Grid>
  );
}



